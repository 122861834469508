<script>
import RoundedContainer from "@/components/RoundedContainer.vue";
import Tag from "@/components/Tag.vue";
import Notification from "@/components/Notification.vue";
import DetailsList from "@/components/DetailsList.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import ModalCancelService from "@/components/transaction-support/ModalCancelService.vue";
import formatDateTimezone from "@/package/helpers/format-date-timezone";
import ModalCompletingDeal from "@/components/transaction-support/ModalCompletingDeal.vue";
import ModalDispute from "@/components/transaction-support/ModalDispute.vue";
import { STATUS_OPERATIONS } from "@/package/const/service-tsp";
import { mapActions, mapState } from "vuex";

export default {
  name: "SectionHistoryService",
  components: {
    MainButton,
    DetailsList,
    Notification,
    RoundedContainer,
    Tag,
    ModalCancelService,
    ModalCompletingDeal,
    ModalDispute,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isShowModal: false,
      isShowModalCompleting: false,
      isShowModalDispute: false,
    };
  },

  computed: {
    ...mapState({
      isLoadingChangeStatus: (state) =>
        state.transactionSupport.isLoadingChangeStatus,
    }),
    detailsList() {
      return [
        {
          left: "Стоимость услуги",
          right: `${this.data.price.toLocaleString()} RC`,
        },
        {
          left: "Дата обращения",
          right: formatDateTimezone(
            this.data.created_at
          ).toFormat("d MMMM, HH:mm", { locale: "ru" }),
        },
        { left: "Номер заявки", right: `№${this.data.id}` },
      ];
    },

    statusOperation() {
      return STATUS_OPERATIONS[this.data.status.text];
    },

    modalSettings() {
      const service = this.$route.query?.service;

      if (service === "provided") {
        return {
          textHeader: "Отмена услуги",
          textButton: "Отменить услугу",
          requiredComment: false,
          callback: this.callbackModalProvided,
        };
      }

      return {
        textHeader: "Отмена услуги",
        textButton: "Отменить услугу",
        requiredComment: this.data.status.text === "in_work",
        callback: this.callbackModalReceived,
      };
    },

    modalSettingDispute() {
      const service = this.$route.query?.service;

      if (service === "provided") {
        return {
          text:
            "Пожалуйста, укажите причину, по которой вы решили оспорить отмену услуги",
          callback: this.disputeModalProvided,
        };
      }

      return {
        text:
          "Пожалуйста, укажите причину, по которой вы решили оспорить завершение услуги",
        callback: this.disputeModalReceived,
      };
    },

    isShowControlsReceived() {
      const statuses = ["new", "in_work"];

      return (
        this.$route.query?.service === "received" &&
        statuses.includes(this.data.status.text)
      );
    },

    showNotification() {
      // this.$route.query?.service === "received"
      const serviceQuery = this.$route.query?.service;
      const initiator = this.data.status.initiator_type_converted;
      const comment = this.data.status.comment;
      const status = this.data.status.text;
      const previousStatus = this.data.previous_status?.text;

      if (status === "canceled") {
        if (previousStatus === "awaited_cancel") return null;

        if (initiator === "distributor" && serviceQuery === "provided") {
          const obj = {
            title: "Клиент отказался от услуги",
            type: "warning",
          };

          if (comment) {
            obj.subTitle = "Комментарий клиента:";
            obj.message = comment;
          }

          return obj;
        }

        if (initiator === "consultant" && serviceQuery === "received") {
          const obj = {
            title: "Консультант отказался от услуги",
            type: "warning",
          };

          if (comment) {
            obj.subTitle = "Комментарий консультанта:";
            obj.message = comment;
          }

          return obj;
        }

        return null;
      }

      if (status === "awaited_approve" && serviceQuery === "received") {
        return {
          type: "success",
          message:
            "Консультант подтвердил оказание услуги. Вы можете завершить услугу.",
        };
      }

      if (status === "awaited_cancel" && serviceQuery === "provided") {
        const obj = {
          title: "Клиент отказался от услуги",
          type: "warning",
        };

        if (comment) {
          obj.subTitle = "Комментарий клиента:";
          obj.message = comment;
        }

        return obj;
      }

      return null;
    },
  },

  methods: {
    ...mapActions({
      cancelReceived: "transactionSupport/cancelReceived",
      cancelProvided: "transactionSupport/cancelProvided",
      inworkProvided: "transactionSupport/inworkProvided",
      approveProvided: "transactionSupport/approveProvided",
      disputeProvided: "transactionSupport/disputeProvided",
      disputeReceived: "transactionSupport/disputeReceived",
    }),

    toggleShowModal(value) {
      this.isShowModal = value ?? !this.isShowModal;
    },

    toggleShowModalCompleting(value) {
      this.isShowModalCompleting = value ?? !this.isShowModalCompleting;
    },

    toggleShowModalDispute(value) {
      this.isShowModalDispute = value ?? !this.isShowModalDispute;
    },

    callbackModalReceived(message = "") {
      this.cancelReceived({
        id: this.$route.params?.id,
        comment: message,
      }).then(() => {
        this.toggleShowModal(false);
      });
    },

    callbackModalProvided(message = "") {
      this.cancelProvided({
        id: this.$route.params?.id,
        comment: message,
      }).then(() => {
        this.toggleShowModal(false);
      });
    },

    disputeModalProvided(message = "") {
      this.disputeProvided({
        id: this.$route.params?.id,
        comment: message,
      }).then(() => {
        this.toggleShowModalDispute(false);
      });
    },

    disputeModalReceived(message = "") {
      this.disputeReceived({
        id: this.$route.params?.id,
        comment: message,
      }).then(() => {
        this.toggleShowModalDispute(false);
      });
    },
  },
};
</script>

<template>
  <RoundedContainer class="section-history-service">
    <div class="section-history-service__header">
      <h2>{{ data.service.name }}</h2>

      <Tag
        class="section-history-service__status"
        :title="statusOperation.text"
        size="l"
        :color-inline="statusOperation.color"
      />
    </div>

    <Notification
      v-if="showNotification"
      :type="showNotification.type"
      :sub-title="showNotification.subTitle"
      :message="showNotification.message"
      :title="showNotification.title"
    />

    <div class="description-html" v-html="data.service.description"></div>

    <div class="section-history-service__line" />

    <DetailsList :list="detailsList" />

    <div class="section-history-service__btn-box">
      <template v-if="$route.query?.service === 'provided'">
        <template v-if="data.status.text === 'new'">
          <MainButton
            title="Принять заявку"
            color="gold"
            :loading="isLoadingChangeStatus"
            @click="inworkProvided({ id: $route.params?.id })"
          />

          <MainButton
            title="Отклонить"
            color="outlined-grey"
            @click="toggleShowModal(true)"
          />
        </template>

        <template v-if="data.status.text === 'in_work'">
          <MainButton
            title="Завершить услугу"
            color="gold"
            :loading="isLoadingChangeStatus"
            @click="approveProvided({ id: $route.params?.id })"
          />

          <MainButton
            title="Отказаться от услуги"
            color="outlined-grey"
            @click="toggleShowModal(true)"
          />
        </template>

        <template v-if="data.status.text === 'awaited_cancel'">
          <MainButton
            title="Подтвердить отказ"
            color="gold"
            @click="callbackModalProvided"
          />

          <MainButton
            title="Оспорить"
            color="outlined-grey"
            @click="toggleShowModalDispute(true)"
          />
        </template>
      </template>

      <template v-else>
        <template v-if="isShowControlsReceived">
          <MainButton
            title="Отказаться от услуги"
            color="outlined-grey"
            @click="toggleShowModal(true)"
          />
        </template>

        <template v-if="data.status.text === 'awaited_approve'">
          <MainButton
            title="Завершить услугу"
            color="gold"
            @click="toggleShowModalCompleting(true)"
          />

          <MainButton
            title="Оспорить"
            color="outlined-grey"
            @click="toggleShowModalDispute(true)"
          />
        </template>
      </template>
    </div>

    <ModalCancelService
      :is-show-modal="isShowModal"
      :modal-settings="modalSettings"
      @callback="modalSettings.callback"
      @close="toggleShowModal(false)"
    />

    <ModalCompletingDeal
      :is-show-modal="isShowModalCompleting"
      @close="toggleShowModalCompleting(false)"
    />

    <ModalDispute
      :is-show-modal="isShowModalDispute"
      :text="modalSettingDispute.text"
      @callback="modalSettingDispute.callback"
      @close="toggleShowModalDispute(false)"
    />
  </RoundedContainer>
</template>

<style lang="scss" scoped>
.section-history-service {
  display: flex;
  flex-direction: column;
  gap: $space-l;
  color: $dark-primary;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    > h2 {
      @include title-3;

      color: inherit;
      word-break: break-word;
    }
  }

  & ::v-deep {
    .section-history-service__unordered-list {
      > li:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__line {
    height: 1px;
    width: 100%;
    background: $outline-light;
  }

  &__btn-box {
    display: flex;
    gap: $space-s;

    > button {
      width: fit-content;
      padding-left: $space-l;
      padding-right: $space-l;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    &:empty {
      display: none;
    }
  }

  &__status {
    @media (max-width: 1023px) {
      position: absolute;
      top: -32px;
      right: 24px;
    }

    @media (max-width: 375px) {
      right: 16px;
    }
  }
}

.description-html {
  color: $dark-primary;
  @include body-1;

  &::v-deep {
    * {
      word-break: break-word;
    }

    ul,
    ol {
      list-style-type: none;
      padding: 0;
      margin: 0;

      > li {
        display: flex;
        align-items: center;
        padding: 0;
        gap: $space-xs;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &::before {
          content: "";
          height: 4px;
          display: inline-block;
          aspect-ratio: 1;
          background: $dark-primary;
          border-radius: 50%;
          margin: 0 $space-s;
        }
      }
    }
  }
}
</style>
