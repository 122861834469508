import { render, staticRenderFns } from "./SectionContactsHistory.vue?vue&type=template&id=79e698ff&scoped=true"
import script from "./SectionContactsHistory.vue?vue&type=script&lang=js"
export * from "./SectionContactsHistory.vue?vue&type=script&lang=js"
import style0 from "./SectionContactsHistory.vue?vue&type=style&index=0&id=79e698ff&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e698ff",
  null
  
)

export default component.exports