<script>
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";
import SectionHistoryService from "@/components/transaction-support/SectionHistoryService.vue";
import SectionContactsHistory from "@/components/transaction-support/SectionContactsHistory.vue";
import { mapActions, mapState } from "vuex";
import MainLoader from "@/components/helpers/MainLoader.vue";

export default {
  name: "ServicesTsp",
  components: {
    MainLoader,
    SectionHistoryService,
    ButtonRedirect,
    SectionContactsHistory,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      currentHistory: (state) => state.transactionSupport.currentHistory,
      isLoadingCurrentHistory: (state) =>
        state.transactionSupport.isLoadingCurrentHistory,
    }),

    propertyHuman() {
      if (this.$route.query?.service === "received") {
        return this.currentHistory.consultant;
      }

      return this.currentHistory.distributor;
    },
  },

  methods: {
    ...mapActions({
      getCurrentHistoryReceived: "transactionSupport/getCurrentHistoryReceived",
      getCurrentHistoryProvided: "transactionSupport/getCurrentHistoryProvided",
    }),
  },

  mounted() {
    const service = this.$route.query?.service;
    const id = this.$route.params?.id;
    const allQuery = ["received", "provided"];

    if (service && allQuery.includes(service)) {
      if (service === allQuery[0]) {
        this.getCurrentHistoryReceived(id);
      } else {
        this.getCurrentHistoryProvided(id);
      }
    } else {
      this.$router.push({ name: "HistoryService" });
    }
  },
};
</script>

<template>
  <div class="services-tsp default-content-padding">
    <div class="services-tsp__header">
      <ButtonRedirect
        class="services-tsp__back"
        text="Назад"
        @click="$router.go(-1)"
      />
    </div>

    <div class="services-tsp__container">
      <MainLoader v-if="isLoadingCurrentHistory" />
      <template v-else-if="currentHistory">
        <SectionHistoryService :data="currentHistory" />
        <SectionContactsHistory :data="propertyHuman" />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.services-tsp {
  padding: $space-xxl $space-xxl $space-xxxl;
  width: 100%;
  height: calc(100% - 64px);

  @media (max-width: 1023px) {
    //background-color: $background-white;
    max-width: none;
    padding: 0;
    //padding: $space-xxl $space-l 0;
    //height: calc(100% - 34px);
    height: 100%;
  }

  @media (max-width: 768px) {
    //padding: $space-xxl $space-l 0;
  }

  @media (max-width: 375px) {
    //padding: $space-xxl $space-m 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: $space-l;
    max-width: 680px;
    width: 100%;
    //height: inherit;

    @media (max-width: 1023px) {
      height: calc(100% - 75.6px);
      max-width: none;
      gap: $space-s;

      > div:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      > div:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: 100%;
      }
    }
  }

  &__header {
    margin-bottom: $space-xxxl;

    @media (max-width: 1023px) {
      background-color: $background-white;
      margin-bottom: 0;
      width: 100%;

      padding: $space-xxl $space-l $space-s;
    }

    @media (max-width: 375px) {
      padding: $space-xxl $space-m $space-s;
    }
  }
}
</style>
