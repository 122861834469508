<script>
import MainModal from "@/package/components/MainModal.vue";
import MainTextarea from "@/components/helpers/MainTextarea.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import { mapState } from "vuex";

export default {
  name: "SectionHistoryService",
  components: {
    MainModal,
    MainTextarea,
    MainButton,
  },

  emits: ["close", "callback"],

  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    modalSettings: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      message: "",
      errorMessage: "",
    };
  },

  computed: {
    ...mapState({
      isLoadingChangeStatus: (state) =>
        state.transactionSupport.isLoadingChangeStatus,
    }),
  },

  watch: {
    message: {
      handler(val) {
        if (this.isShowModal) this.validateMessage(val);
      },
    },
  },

  methods: {
    validateMessage(val) {
      if (val.length <= 0) {
        this.errorMessage = "Заполните поле";

        return;
      }

      this.errorMessage = "";
    },

    closeModal() {
      this.message = "";
      this.errorMessage = "";
      this.$emit("close");
    },

    handleClickBtn() {
      if (this.modalSettings.requiredComment)
        this.validateMessage(this.message);

      if (!this.errorMessage) this.$emit("callback", this.message);
    },
  },
};
</script>

<template>
  <MainModal
    class="modal-cancel-service"
    :show-modal="isShowModal"
    :show-icon-close="!isLoadingChangeStatus"
    @close="closeModal"
  >
    <h2 class="modal-cancel-service__title">{{ modalSettings.textHeader }}</h2>

    <div class="modal-cancel-service__box">
      <p>
        Пожалуйста, укажите причину отказа от услуги, чтобы мы могли улучшить
        наш сервис.
      </p>

      <MainTextarea
        v-model="message"
        title="Комментарий"
        :max-symbol="250"
        :error="errorMessage"
        resize
      />

      <div class="modal-cancel-service__box-btn">
        <MainButton
          :title="modalSettings.textButton"
          color="dark"
          :loading="isLoadingChangeStatus"
          @click="handleClickBtn"
        />
        <MainButton
          title="Отмена"
          color="outlined-grey"
          :disabled="isLoadingChangeStatus"
          @click="closeModal"
        />
      </div>
    </div>
  </MainModal>
</template>

<style lang="scss" scoped>
.modal-cancel-service {
  color: $dark-primary;

  &::v-deep {
    .main-modal__container {
      max-width: 518px;
      width: 100%;
    }
  }

  &__title {
    @include title-3;

    color: inherit;
    margin-bottom: $space-l;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: $space-l;

    > p {
      @include body-1;

      color: inherit;
    }
  }

  &__box-btn {
    display: flex;
    gap: $space-m;

    > button {
      width: fit-content;
      padding-left: $space-l;
      padding-right: $space-l;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }
}
</style>
