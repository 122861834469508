<script>
import MainModal from "@/package/components/MainModal.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "SectionHistoryService",
  components: {
    MainModal,
    MainButton,
  },

  emits: ["close", "callback"],

  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      isLoadingChangeStatus: (state) =>
        state.transactionSupport.isLoadingChangeStatus,
    }),
  },

  methods: {
    ...mapActions({
      approveReceived: "transactionSupport/approveReceived",
    }),

    closeModal() {
      this.$emit("close");
    },

    handleClickBtn() {
      this.approveReceived({ id: this.$route.params.id }).then(() => {
        this.closeModal();
      });
    },
  },
};
</script>

<template>
  <MainModal
    class="modal-cancel-service"
    :show-modal="isShowModal"
    :show-icon-close="!isLoadingChangeStatus"
    @close="closeModal"
  >
    <h2 class="modal-cancel-service__title">Завершение услуги</h2>

    <div class="modal-cancel-service__box">
      <p>
        Нажимая «Завершить услугу», вы&nbsp;подтверждаете получение всех
        необходимых услуг от&nbsp;консультанта.
      </p>

      <div class="modal-cancel-service__box-btn">
        <MainButton
          title="Завершить услугу"
          color="dark"
          :loading="isLoadingChangeStatus"
          @click="handleClickBtn"
        />

        <MainButton
          title="Отмена"
          color="outlined-grey"
          :disabled="isLoadingChangeStatus"
          @click="closeModal"
        />
      </div>
    </div>
  </MainModal>
</template>

<style lang="scss" scoped>
.modal-cancel-service {
  color: $dark-primary;

  &::v-deep {
    .main-modal__container {
      max-width: 518px;
      width: 100%;
    }
  }

  &__title {
    @include title-3;

    color: inherit;
    margin-bottom: $space-l;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: $space-l;

    > p {
      @include body-1;

      color: inherit;
    }
  }

  &__box-btn {
    display: flex;
    gap: $space-m;

    > button {
      width: fit-content;
      padding-left: $space-l;
      padding-right: $space-l;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    @media (max-width: 1023px) {
      flex-direction: column;
      gap: $space-s;
    }
  }
}
</style>
