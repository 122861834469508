<script>
export default {
  name: "Notification",
  components: {},

  props: {
    title: {
      type: String,
      default: "",
    },

    subTitle: {
      type: String,
      default: "",
    },

    message: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      validator: (value) => ["warning", "success"].includes(value),
      default: "",
    },
  },

  data() {
    return {
      icons: {
        warning: "icon-warning-fill",
        success: "icon-circle-done-fill",
      },
    };
  },

  computed: {
    iconNotification() {
      return this.icons[this.type] ?? "";
    },
  },

  methods: {},
};
</script>

<template>
  <article class="notification" :class="type">
    <span v-if="type" class="notification__icon" :class="iconNotification" />

    <div class="notification__body">
      <h6 v-if="title">{{ title }}</h6>

      <div class="notification__content">
        <span v-if="subTitle">{{ subTitle }}</span>

        <p>{{ message }}</p>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.notification {
  display: flex;
  gap: 12px;
  background: $light-second;
  padding: $space-l;
  border-radius: $space-m;
  color: $dark-primary;

  &__icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;

    > h6 {
      @include body-1-bold;

      color: inherit;
    }
  }

  &__content {
    > span {
      @include text-2;

      color: $dark-fifth;
      margin-bottom: $space-xs;
    }

    > p {
      @include body-1;

      color: inherit;
      word-break: break-word;
    }
  }
}

.warning {
  .notification {
    &__icon {
      background: $red;
    }
  }
}

.success {
  .notification {
    &__icon {
      background: $money-cv;
    }
  }
}
</style>
