<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    height: {
      type: Number,
      default: 100,
    },

    resize: {
      type: Boolean,
      default: false,
    },

    maxSymbol: {
      type: Number,
      default: 0,
    },

    error: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      charCount: this.value.length,
    };
  },

  computed: {
    lengthSymbol() {
      return `${this.charCount} / ${this.maxSymbol}`;
    },

    paramSettings() {
      const obj = {};

      if (this.maxSymbol) {
        obj.maxlength = this.maxSymbol;
      }

      return obj;
    },
  },

  methods: {
    updateValue(event) {
      const value = event.target.value.trim();

      if (this.maxSymbol) {
        this.charCount = value.length; // обновляем счётчик символов
      }

      this.$emit("input", value); // передаем новое значение вверх
    },
  },
};
</script>

<template>
  <div
    class="main-textarea"
    :class="[
      { 'main-textarea_resize': resize },
      { 'main-textarea_error': error },
    ]"
  >
    <div
      v-if="title || maxSymbol"
      class="main-textarea__output-box"
      :class="{ 'main-textarea__output-box_red': maxSymbol === this.charCount }"
    >
      <label>{{ title }}</label>

      <span v-if="maxSymbol">{{ lengthSymbol }}</span>
    </div>

    <textarea
      v-bind="paramSettings"
      :value="value"
      :placeholder="placeholder"
      :style="{ height: height + 'px' }"
      @input="updateValue"
    />

    <span v-if="error" class="main-textarea__error">{{ error }}</span>
    <!--    @input="$emit('input', $event.target.value)"-->
  </div>
</template>

<style lang="scss" scoped>
.main-textarea {
  textarea {
    @include body-1;
    color: $dark-primary;
    border: 1px solid #e9e9e9;
    border-radius: $space-s;
    background-color: $light-second;
    padding: 12px $space-m;
    width: 100%;
    height: 100%;
    resize: none;

    &::placeholder {
      color: $light-sixth;
    }

    &:focus {
      outline: 1px solid $gold-main;
    }
  }

  &_resize {
    > textarea {
      resize: vertical;
    }
  }

  &__output-box {
    margin-bottom: $space-s;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    > label {
      @include text-2;

      color: $dark-sixth;
      margin-left: $space-xs;
    }

    > span {
      @include text-2;

      color: $dark-sixth;
    }
  }

  &__output-box_red {
    > span {
      color: $red;
    }
  }

  &_error {
    > textarea {
      border: 1px solid $red;

      &:focus {
        outline: none;
      }
    }
  }

  &__error {
    @include caption-1;

    color: $red;
    margin-top: $space-s;
    margin-left: $space-xs;
  }
}
</style>
